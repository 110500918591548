.add-item-form-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0;
    padding: 1rem 2rem;
    border-radius: 20px;
    border: 1px solid #000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.add-item-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0;
    padding: 1rem;
    gap: 1rem;
    width: 60%;
    height: 100%;
    min-height: 300px;
    min-width: 600px;
}
.add-form-qty{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.add-form-cell{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.add-form-cell label{
    color: #000;
    font-family: Raleway;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.add-form-cell2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    width: 70%;    
}
.add-form-cell2 label, .add-form-buttons h3{
    color: #000;
    font-family: Raleway;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.add-form-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.add-form-file{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 70%;
}
.upload-button{
    opacity: 0;
    position:absolute;
    width:100%;
    height:100%;
    cursor:pointer;
}
.add-item-preview{
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.add-item-preview h3{
    color: #000;
    font-family: Raleway;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.add-item-preview embed{
    height: 250px;
    width: 450px;
}