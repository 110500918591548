.lead-information-container{
  padding: 2rem;
}

.lead-information-heading{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 2rem;
}

.lead-information-head-buttons{
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid #E0E0E0;
}



.lead-info-heading-text{
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 1rem 0;
}
.lead-info-remark{
  border-radius: 0.6rem;
  border: 1px solid #000;
  background: #EBEBEB;
  color: #000;
  font-size: 1.1rem;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.85rem 1rem;
  margin: 1rem 0;
}
.lead-info-comment-heading{
  color: #000;
  font-size: 1.3rem;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 2rem 0 1rem;
}


















.lead_information_subcontainer {
    display: flex;
    flex-direction: row;
    width: 1100px;
    align-items: flex-start;
    justify-content: space-around;
    text-align: center;
  }
  

  /* .lead_information_subcontainer{
    display: grid;

  } */


  .buttons{
    display: flex;
    flex: 0.5;
    flex-direction: column;
    align-items: flex-end;
  }

  .error{
    text-align: center;
    color: red;
  }