.login{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.login img{
    height: 100vh;
    width: 65%;
}

.login span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
}