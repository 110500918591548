.my-leads-container{
    padding: 1.5rem;
}
.my_leads_container{
    align-items: center;
    justify-content: center;
   
}

.table-container {
    /* max-height: 630px; Set the desired max-height for the table */
    max-width: 100%; /* Adjust the max-width to make the table fit the screen */
    /* Remove overflow-y property to prevent vertical scrolling */
    position: sticky;
    top: 100px; /* Adjust the distance from the top of the screen as needed */
    z-index: 1; /* Set a higher z-index to make sure it appears above other content */
  }
  
  .table-container table {
    width: 100%;
  }


.my-leads-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin: 1rem 0;
}
.my-leads-heading-text{
    font-family: Raleway;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: center;
}
.my-leads-buttons{
    border: solid;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.my-leads-sort{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 4rem;
}
.my-leads-sort h2,.my-leads-menu h2{
    font-family: Raleway;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2rem;
    letter-spacing: 0em;
    text-align: center;
    margin-right: 1.5rem;
}
.my-leads-menu{
    width: 750px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 35px; /* Adjust padding as needed */
      
}


.my_leads_header{
    color: #1976d2;
    text-align: center;
}