/* .new-pet-add-button-container {
    margin-right: 50px;
  }

.new-pet-go-back-button{
  margin-left: 50px ;
} */

.new-pet-button-container{
  display: flex;
  flex-direction: row;
  
  margin: 1rem 0;
}