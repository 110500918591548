.lead-card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 12.5vw;
    height: 12.5vh;
    border-radius: 1.3rem;
}
.lead-card-container h3{
    color: #1C1C1C;
    font-size: 1rem;
    font-family: Raleway;
    font-weight: 600;
    line-height: 1.2rem;
}
.lead-card-container button{
    color: #1C1C1C;
    font-size: 1.5rem;
    font-family: Raleway;
    font-weight: 600;
    line-height: 1.7rem;
}