.lead-info-item-inventory-head{
    color: #000;
    font-size: 1.6rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 2rem 0 1rem;
}
.lead-info-item-inventory-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.lead-info-item-inventory-item{
    border-radius: 0.8rem;
    background: #DCE0E4;
    padding: 1rem;
    width: 170px;
    height: 150px;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}
.lead-info-item-inventory-item[add="true"]{
    cursor: pointer;
}
.lead-info-item-inventory-item img{
    width: 100%;
    height: 100px;
}
.lead-info-item-inventory-item h4{
    color: #000;
    text-align: center;
    font-size: 1.1rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0.4rem;
}
.lead-info-item-inventory-item button{
    color: #000;
    text-align: center;
    font-size: 0.7rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin: 0;
    padding: 0;
}
.lead-info-item-inventory-item-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.35rem;
}
.lead-info-item-inventory-item h5{
    color: #000;
    font-size: 0.8rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0.2rem;
}

.register-new-lead-form{
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.lead-info-expense-inventory-head{
    color: #000;
    font-size: 1.6rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 2rem 0 1rem;
}
.lead-info-expense-inventory-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.lead-info-expense-inventory-expense{
    border-radius: 0.8rem;
    background: #DCE0E4;
    padding: 1rem;
    width: 170px;
    height: 150px;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}
.lead-info-expense-inventory-expense[add="true"]{
    cursor: pointer;
}
.lead-info-expense-inventory-expense img{
    width: 100%;
    height: 100px;
}
.lead-info-expense-inventory-expense h4{
    color: #000;
    text-align: center;
    font-size: 1.1rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0.4rem;
}
.lead-info-expense-inventory-expense button{
    color: #000;
    text-align: center;
    font-size: 0.7rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin: 0;
    padding: 0;
}
.lead-info-expense-inventory-expense-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.35rem;
}
.lead-info-expense-inventory-expense h5{
    color: #000;
    font-size: 0.8rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0.2rem;
}

.register-new-lead-form{
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

