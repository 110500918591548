.lead-info-docs-req-head{
    color: #000;
    font-size: 1.6rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 1rem 0 1rem;
}
.lead-info-docs-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.lead-info-docs-heading select{
    margin: 1rem;
    width: 150px;
}
.lead-info-docs-req-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.8rem;
    border: 1px solid #000;
    padding: 1rem;
    margin: 0.5rem 0;
}
.lead-info-docs-req-item-head{
    color: #000;
    font-size: 1rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.upload-button{
    opacity: 0;
    position:absolute;
    width:100%;
    height:100%;
    cursor:pointer;
    z-index: 10;
}

.document-divider{
    width: 400px;
    justify-content: flex-end;
    align-items: right;
    display: flex;
    flex-direction: row;
    
}

.document{
   
    padding: 1rem;
   
}