.lead-info-actionable-items-head{
    color: #000;
    font-size: 1.6rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 2rem 0 1rem;
}
.lead-info-actionable-items-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}
.lead-info-actionable-items-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}
.lead-info-actionable-items-cell{
    border-radius: 30px;
    border: 1px solid #C1B7B7;
    background: #FFF;
    color: #000;
    font-size: 0.9rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 94%;
    height: 125px;
    margin: 1rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0 2rem;
    flex-direction: column;
}
.lead-info-actionable-items-cell hr{
    width: 100%;
    height: 1px;
    background: #C1B7B7;
}
.lead-info-actionable-items-cell p{
    margin: 1.2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.lead-info-actionable-items-cell p[clickable="true"]{
    cursor: pointer;
}
.actionable-items-pop-up{
    position: absolute;
    
}

.register-payment-form{
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.edit-form-cell{
    margin: 0.3rem;
}