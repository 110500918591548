.lead-info-payment-head{
    color: #000;
    font-size: 1.6rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 2rem 0 1rem;
    /* color: #bab7b7; */
}

.lead-info-payment-amount-entry{
    display: flex;
    flex-direction: row;
    justify-content: center;
}