.new-parent-info-button-section{
    display: flex;
    flex-direction: row;
    width: 640px;
    margin-left: 50px;
    justify-content: space-evenly;
}

.new-parent-info-goto-pet-button-section{
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
  }