.email-container{
    border: 1.5px solid #E0E0E0;
    padding: 5px;
    width: 100%;
    height: 600px;
    display: flex;
}

.email-button-holder{
    border-right: 1.5px solid #E0E0E0;
    padding-right: 5px;
    width: 15%;
    height: 100%;
    
    
}

.email-buttons{
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.email-content-container{
    width: 85%;
}

.mailer-container{
    padding: 1.5rem;
    color: #000;
    font-family: Raleway;
    font-style: normal;
    line-height: normal;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 5;
    position: relative;
}
.mailer-background{
    height: calc(100vh - 70px);
    opacity: 0.85;
    width: 100vw;
    object-fit: cover;
    position: absolute;
    z-index: 0;
    bottom: 0;
}
.mailer-sidebar h1{
    font-size: 2.5rem;
    cursor: pointer;
}
.mailer-sidebar-buttons{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 3rem;
    align-items: flex-start;
}
.mailer-main{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.mailer-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.mailer-search{
    height: 40px;
    border-radius: 20px;
    width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;

    background-color: white;
    opacity: 0.9;
}
.mailer-search input{
    width: 90%;
    height: 60%;
    border: none;
    font-size: 1.1rem;
    outline: none;
}
.mailer-profile{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: blue;
}

