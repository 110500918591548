.dashboard-container{
    padding: 1rem 1.5rem;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
/* .dashboard-petspot-heading{
    color: #000;
    text-align: center;
    font-size: 3.5rem;
    font-family: Raleway;
    font-weight: 600;
    margin: 3rem 0rem 4.5rem;
} */
.dashboard-container hr{
    background: #B7B7B7;
    height: 1px;
}
.dashboard-heading-container{
    /* height: 22vh; */
}
.dashboard-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0rem 0.5rem;
}
.dashboard-heading h2{
    color: #000;
    text-align: center;
    font-size: 2rem;
    font-family: Raleway;
    font-weight: 600;
}
.dashboard-button{
    border-radius: 0.5rem;
    background: #76BFF3;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none;
    padding: 0.4rem 2rem;
    color: #FFF;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 700;
    cursor: pointer;
}
.dashboard-leads{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 1rem 0.5rem;
}
.dashboard-grid{
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 0rem 8rem;
    overflow-y: hidden;
}
.dashboard-graph{
    height: 250px;
    margin: 0.5rem;
}
.dashboard-liveInventory{
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
}
.dashboard-liveInventory-heading{
    color: #1C1C1C;
    font-size: 1.5rem;
    font-family: Raleway;
    font-weight: 600;
    line-height: 1.6rem;
    margin-bottom: 1rem;
}
.dashboard-liveInventory-items{

}
.dashboard-form-container{
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
}
.dashboard-form-container h3{
    color: #1C1C1C;
    font-size: 1.5rem;
    font-family: Raleway;
    font-weight: 600;
    line-height: 1.6rem;
}
.dashboard-form{
}
.dashboard-form-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 1rem;
    width: 60%;
    justify-content: space-between;
}
.dashboard-form-input label{
    color: #000;
    font-size: 1.3rem;
    font-family: Raleway;
    margin-right: 3rem;
}
.dashboard-form-input input{
    border-radius: 10px;
    border: 2px solid #D8DDE3;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(23, 25, 28, 0.05);
    width: 70%;
    height: 40px;
    outline: none;
    padding: 0.5rem 1rem;
}
.dashboard-cargoAgentFees{
    margin: 0.5rem;
}
.dashboard-cargoAgentFees-heading{
    color: #1C1C1C;
    font-size: 1.5rem;
    font-family: Raleway;
    font-weight: 600;
    line-height: 1.6rem;
    margin-bottom: 1rem;
}
.dashboard-cargoAgentFees-items{

}