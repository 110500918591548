.transactions{
    padding: 1.5rem;
}
.transactions-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 2rem;
}
.transactions-heading h1{
    color: #000;
    font-size: 2rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1px;
}
.transactions-head-buttons{
    border-radius: 25px;
    overflow: hidden;
    border: 1px solid #E0E0E0;
}
.transactions-head-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}