/* ViewEmail.css */

.view-email-container {
    padding: 16px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
  }
  
  .view-email-back-button {
    margin-bottom: 16px;
    background-color: #ffffff;
    color: #1a73e8;
  }
  
  .view-email-email-subject {
    font-size: 24px;
    margin-top: 16px;
  }
  
  .view-email-section-header {
    font-size: 18px;
    margin-top: 16px;
  }
  
  .view-email-email-body {
    margin-top: 8px;
  }
  
  .view-email-attachment {
    margin-top: 8px;
  }
  
  .view-email-attachment-button {
    background-color: #ffffff;
    color: #1a73e8;
    text-decoration: underline;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
  }
  
  .view-email-attachment-button:hover {
    text-decoration: none;
  }
  
  .view-email-files {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
  }
  
  .view-email-file-card {
    background-color: white;
    border-radius: 20px;
    padding: 0.25rem 0.5rem;
  }
  
  .view-email-file-card p {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }