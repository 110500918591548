.new-mail{
    margin: 1.5rem 0rem 1.5rem 2rem;
    height: 87%;
    overflow-y: scroll;
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
}

.new-mail::-webkit-scrollbar {
    display: none;
}
.new-mail {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.new-mail-input{
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
}
.new-mail-input label{
    font-size: 1.2rem;
    font-weight: 600;
}
.new-mail-input input,.new-mail-subject{
    flex-grow: 1;
    padding: 0.25rem 0rem;
    font-size: 1.1rem;
    background-color: rgba(0,0,0,0);
    border: none;
    outline: none;
    font-weight: 400 !important;
    font-family: 'Raleway';
    margin-right: 2rem;
    height: 40px;
}
.new-mail-subject{
    flex-grow: 0;
    margin: 0.25rem 0;
}
/* .new-mail-message textarea{
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 1.1rem;
    margin: 0;
    min-height: 280px !important;
    margin-right: 2rem;
} */
.new-mail-message{
    margin-top: 0.5rem;
    font-size: 1.1rem;
    outline: none;
    margin-right: 2rem;
    min-height: 300px;
}
.new-mail-functions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background: rgba(255,255,255);
    height: 55px;
}
.new-mail-edit{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
}
.new-mail-files{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
}
.new-mail-file-card{
    background-color: white;
    border-radius: 20px;
    padding: 0.25rem 0.5rem;
}
.new-mail-file-card p{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}