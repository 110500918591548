.inventory-item{
    border-radius: 0.8rem;
    background: #EEE;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    margin: 1rem 0;
    height: 150px;
}
.inventory-item-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    height: 100%;
}
.inventory-item-info img{
    width: 170px;
}
.inventory-item-grid{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}
.inventory-item-col{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.inventory-item-col h2{
    color: #000;
    font-size: 1.5rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.inventory-item-col h3{
    color: #000;
    font-size: 1.3rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.inventory-item-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* width: 30%; */
}
.inventory-item-qty{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 40%;
}
.inventory-item-qty h2{
    color: #000;
    font-size: 1.3rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 3rem;
}
.bold{
    font-weight: bold !important;
    font-size: 1.5rem !important;
}
.inventory-item-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    margin-left: 8rem;
}
.inventory-item-edit > button{
    height: 100%;
    width: 50%;
    border: 1px solid #D8DDE3;
    border-radius: 0;
}
.inventory-item-edit{
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid #D8DDE3;
    margin-bottom: 0.5rem;
    height: 60px;
    width: 100px;
}