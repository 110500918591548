.invoice-container{
    padding: 2rem;
}
.invoice-heading{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.invoice-names{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}
.invoice-container h3,.invoice-container li{
    color: #000;
    font-size: 1rem;
    font-family: Raleway;
    font-weight: 600;
    line-height: normal;
}
.invoice-container li{
    font-weight: 500;
    font-size: 0.95rem;
}
.invoice-styled{
    font-style: italic;
    color: #7f6000 !important;
}
.invoice-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}
.invoice-table-head{
    font-size: 1.2rem !important;
}
.invoice-relocation{
    margin-left: 2rem;
    margin-top: 2rem;
}
.invoice-relocation ol{
    list-style-position: inside;
}
.invoice-terms{
    margin-left: 2rem;
    margin-top: 2rem;
}
.invoice-terms ul{
    list-style-position: inside;
}
.col-add{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 20px;
}
.invoice-input{
    border: none;
    outline: none;
    background-color: rgba(255,255,255,0);
    font-family: 'Raleway';
    font-size: 0.9rem;
    width: 100%;
    resize: none;
    height: auto;
    overflow-y: scroll;
    text-align: center;
    height: 35px;
}
.invoice-input::-webkit-scrollbar {
    width: 0;
}
.invoice-input {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.add-delete-icons-container{
    display: flex;
    flex-direction: row ;
    justify-content: space-around;
}

.add-or-delete-column .add-or-delete-row{
    display: flex;
    align-items: center;
}

.modify-icons-in-add-delete{
    display: flex;
    justify-content: space-around;
}