.inbox-container{
    position: relative;
    border-radius: 20px;
    height: 75vh;
    width: 100%;
    background-color: rgba(255,255,255,0.7);
    overflow: hidden;
    padding-bottom: 1.5rem;
}
.inbox{
    height: 95%;
    overflow-y: scroll;
    position: relative;
    z-index: 5;
    margin: 1.5rem 0rem;

    display: flex;
    flex-direction: column;
}
.inbox::-webkit-scrollbar {
    display: none;
}
.inbox {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.inbox-card{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    padding: 0.8rem 2rem;
    border-bottom: 1px solid rgb(216, 216, 216);
}
.inbox-card:hover{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.inbox-card h3{
    font-weight: 500;
    font-size: 0.9rem;
}
.inbox-card-sender{
    width: 17%;
}
.inbox-card-message{
    width: 73%;
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
}
.inbox-card-message h3:last-child{
    color: rgb(128, 128, 128) !important;
    font-size: 0.85rem !important;
}
.inbox-card-date{
    width: 10%;
    text-align: right;
}

.mail-info{
    margin: 0.5rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.mail-info-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 -3.5rem;
}
.mail-info-subject{
    font-weight: 500;
}
.mail-info-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}
.mail-info-title h2{
    font-size: 1.2rem;
    font-weight: 700;
}
.mail-info-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
}
.mail-info-options h4{
    font-weight: 400;
    font-size: 1rem;
}
.mail-info-body{
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}
.mail-info-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin: 5rem 0;
}

.inbox-header{
    width: 100%;
    background-color: white;
    padding: 0 2rem;
    margin-bottom: -1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}