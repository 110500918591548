.email-template-header{
    text-align: center;
    padding-top: 1rem;
    color: chocolate;
}

.toolbar{
    display: flex;
    flex-direction: column;
    justify-content: space-around;  
}

.all-templates{
    display: 'flex'; 
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    max-height: 300px;
}