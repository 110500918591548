.inventory-item-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem;
}
.inventory-item-name{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.inventory-item-name img{
    height: 2rem;
}
.inventory-item-name h3{
    color: #1C1C1C;
    font-size: 1.1rem;
    font-family: Raleway;
    line-height: 1.3rem;
    margin-left: 1rem;
    margin-bottom: 0;
    font-weight: 500;
}
.inventory-item-status{
    color: #F00;
    font-size: 1.1rem;
    font-family: Raleway;
    line-height: 1.3rem;
    margin-bottom: 0;
    font-weight: 500;
}