.popup-wrapper{
    width: 100vw;
    height: 100vh;
    background: rgb(199,199,199,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
}
.popup-div{
    position: fixed;
    /* height: 400px;
    width: 600px; */
    border-radius: 25px;
    background-color: white;
    padding: 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.popup-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    width: 100%;
}
.popup-heading h1{
    color: #000;
    font-size: 1.7rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.1px;
}