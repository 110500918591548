.microchip{
    padding: 1rem 0.5in;
}
.microchip-heading{
    color: #000;
    font-size: 2.5rem;
    font-family: Raleway;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.microchip-text{
    color: #000;
    font-size: 1rem;
    font-family: Raleway;
    font-weight: 600;
    line-height: normal;
    margin-top: 1.5rem;
}
.microchip-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    margin-top: 1.5rem;
}
.microchip input{
    color: #000;
    font-size: 1rem;
    font-family: Raleway;
    font-weight: 500;
    line-height: normal;
    padding: 0.3rem 1rem;
    border: none;
    border-bottom: 1px solid black;
    
    border-radius: 0;
    margin: 0 1rem;
    align-items: center;
}
.microchip-head-2{
    color: #000;
    font-size: 1rem;
    font-family: Raleway;
    font-weight: 700;
    line-height: normal;
}
.table-input{
    width: 100% !important;
    margin: 0 !important;
    border: none !important;
}
.signature{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 3rem;
}
.microchip table{
    gap: 0;
    width: 100%;
    margin-top: 1.5rem;
}
.microchip table td,.microchip table th{
    font-weight:500;
    font-family:Raleway;
    border:1px solid rgb(50,50,50);
    width: 10%;
    padding: 0.5rem;
}
.pad{
    margin: 4rem;
}
@media print {
    .not-print{
      display: none;
    }
    .microchip{
        padding: 1rem;
    }
    .signature-input{
        border-bottom: 1px solid black !important;
    }
    .microchip input{
        border: none ;
    }
    @page {
        size: auto; /* Use 'auto' or specify specific print paper size */
        margin: 0 2rem; /* Set margins to 0 */
    }
    @media print {
        .header,
        .footer,
        .page-number {
          display: none;
        }
    }
}