.add_leads_header{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.add_leads_boxes{
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.first_box .second_box{
    margin: 10px;
}