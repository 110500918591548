.follow-up-leads-head-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 1.5rem;
}
.follow-up-leads-head-container h1{
    color: #000;
    text-align: center;
    font-size: 2rem;
    font-family: Raleway;
    font-weight: 600;
}
.follow-up-lead-items{
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 100%;
    flex-wrap: wrap;
}
.follow-up-lead-item{
    width: calc(50% - 0.8rem);
    border-radius: 20px;
    background: #EEE;
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
}
.follow-item-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.follow-up-lead-item h3{
    color: #000;
    font-size: 1.3rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.follow-up-lead-item h2{
    color: #000;
    font-size: 1.5rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.follow-item-head{
    color: #000;
    font-size: 1.7rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.1px;
}