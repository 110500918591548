.inventory{
    padding: 2rem;
}
.inventory-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.inventory-items{
    margin-top: 2.5rem;
}
.inventory-heading h1{
    color: #000;
    font-size: 3rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.inventory-edit-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* height: 400px; */
    margin: 1.5rem 0;
    padding: 1rem;
    border-radius: 20px;
    border: 1px solid #000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    gap: 1rem;
}
.edit-form-qty{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 700px;
    gap: 1rem;
}
.edit-form-cell{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.edit-form-cell label{
    color: #000;
    font-family: Raleway;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.edit-form-cell2{
    display: flex;
    align-items: center;
    width: 700px;
    justify-content: space-between;
    gap: 1rem;
}
.edit-form-cell2 label, .inventory-edit-form h3{
    color: #000;
    font-family: Raleway;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.edit-form-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 700px;
}